.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
 }
 
.homepage-title {
  font-family: "Sheeping-Dogs";
  font-size: 50px;
  text-align: center;
  margin-bottom: 0;
  padding: 10px 10px 0;
}

.homepage-description {
  margin-bottom: 30px;
  font-size: 1.1rem;
}
.logo-container {
  margin: 0 auto;
  width: 230px;
}
.logo {
  /*
  animation-iteration-count: infinite;
  animation: slidein 1s cubic-bezier(0, 0, 0.35, 1);
  animation-iteration-count:1;
  animation-direction: alternate;
  */
}

.create-board {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 400px;
  margin: 0 30px;
}

@keyframes slidein {
   0% { transform: translateY(-900px); }
   100% { transform: translateY(0px); }
}
.speech-bubble {
  position: relative;
  background: whitesmoke;
  border-radius: .4em;
  padding: .8rem;
  max-width: 320px;
}

.speech-bubble p {
  margin: 0;
  color: black;
  font-weight: 300;
  font-style: italic;
  font-size: .8rem;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: whitesmoke;
  border-bottom: 0;
  border-right: 0;
  margin-left: -100px;
  margin-bottom: -20px;
}
