.card {
  border: 1px solid rgba(0, 0, 0, 0); /* To prevent jank */
  border-bottom: 1px solid #ccc;
  position: relative;
  padding: 0;
}

.card__head {
  display: inline-flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
}

.card__votes {
  display: inline-block;
  font-size: 14px;
  font-family: monospace;
  margin-left: 10px;
  color: #999;
}

.card__drawer {
  display: flex;
  flex-direction: column;
}

.card__subcard {
  background-color: white;
  border-radius: 3px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
}
.card__subcard:last-child {
  margin: 0;
}

.card__subcards {
  display: flex;
  flex-direction: column;
  background-color: #ebebeb;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 15px;
}

.card__actions {
  width: 100%;
  display: inline-flex;
  justify-content: space-around;
  padding: 5px 0;
}
.card__actions span {
  flex-grow: 1;
  text-align: center;
  margin: 5px;
}
.card__actions svg {
  width: 15px !important;
  height: 15px !important;
}

.card__info {
  display: flex;
}

.card__markdown * {
  line-height: 1.2em;
}

.card__icon {
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
}
.card__icon svg {
  width: 15px !important;
  height: 15px !important;
  color: #999 !important;
}

.card--can-drop {
  border: 1px solid #00bdd4;
  box-shadow: 0px 0px 2px #00bdd4 inset;
}
.card--is-over.card--can-drop {
  border: 1px solid #00bdd4;
  box-shadow: 0px 0px 2px #00bdd4 inset;
  background-color: rgba(0, 189, 212, 0.4);
}
