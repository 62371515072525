.column-container {
  background-color: #e7e7e7 !important;
}

.column {
  padding-top: 0;
  position: relative;
  flex: 1;
  padding: 7px 7px 35px 7px;
  border-radius: 3px;
  min-width: 300px;
}

@media (max-width: 500px) {
  .column {
    min-width: 95vw;
    scroll-snap-align: center;
  }
}

.column__card-list {
  padding-top: 0;
  margin: 8px 8px 20px;
}

.column__title {
  flex-grow: 1;
  color: black;
}

.column__close {
  cursor: pointer;
  color: #ccc;
}

.column__selected-indicator {
  position: absolute;
  top: -25px;
}

.column__header {
  border: 1px solid rgba(0, 0, 0, 0); /* To prevent jank */
  display:flex;
}
.column__header--can-drop {
  border: 1px solid #00bdd4;
  box-shadow: 0px 0px 2px #00bdd4 inset;
}
.column__header--is-over.column__header--can-drop {
  background-color: rgba(0, 189, 212, 0.4);
}
