@font-face {
  font-family:"Sheeping-Dogs";
  src: url('assets/fonts/Sheeping-Dogs.ttf');
}

html {
  min-height: 100%;
}
*, *:before, *:after {
  box-sizing: border-box;
}
body {
  height: 100%;
  /* ref: http://www.designbolts.com/2012/11/26/25-free-simple-black-seamless-patterns-for-website-backgrounds/ */
  background: url('assets/black_scales.png');
  color: #fff;
  margin: 0;
  font-family: sans-serif;
}

header {
  background-color: #444;
  color: #fff;
  width: 100%;
  position: fixed;
  top: 0px
}
h1, h2, h3, h4 {
  margin: 0;
  padding: 10px;
}
h1 {
  font-weight: normal;
  font-size: 20px;
}

.app-bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.app-bar a {
  text-decoration: none;
  color: white;
}

.toolbar {
  display: flex;
  justify-content: space-between;
}

.toolbar__add-icon {
  color: white;
}

.toolbar__title {
  font-family: Sheeping-Dogs, serif;
  font-size: 1.6rem;
}

.board-title {
  margin: 60px 0 15px;
  color: white;
}

.columns {
  margin-bottom: 20px;
  display: flex;
  overflow: scroll;
  scroll-snap-type: x mandatory;
}

ul {
  padding: 0;
  margin: 0;
}
li {
  background-color: #fff;
  color: #000;
  margin: 7px 0 0 0;
  padding: 10px;
  border-radius: 3px;
  list-style-type: none;
}

.add-card-placeholder {
  position: absolute;
  bottom: 0px;
  margin-bottom: 5px;
}
.add-card-placeholder a {
  color: #999;
}

.dialog-title h2 {
  padding: 0;
}

.dialog {
  min-width: 320px;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* html .clearfix             { zoom: 1; } /* IE6 */
*:first-child+html .clearfix { zoom: 1; } /* IE7 */
